import * as React from 'react';
import {
  VStack,
  Heading,
  ListItem,
  OrderedList,
  Flex,
  UnorderedList,
} from '@chakra-ui/react';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

const EndUserLicenseAgreement: React.FC = () => {
  const mixpanel = useMixpanel();
  React.useEffect(() => {
    mixpanel.track('Homepage: Viewed End User License Agreement');
  }, [mixpanel]);

  return (
    <Layout>
      <SEO title="End User License Agreement" />
      <Flex direction="column" align="center" justify="center">
        <Heading px={10} pt={10} textAlign="center">
          Canyou End User License Agreement
        </Heading>
        <VStack p={10} mx={5} spacing={4} maxWidth="900px">
          <OrderedList>
            <ListItem>
              Acceptance of this End User License Agreement
              <UnorderedList>
                <ListItem listStyleType="none">
                  1.1 API Software Pty Ltd t/a Canyou Software (ABN 57 650 764
                  195) (us, we or our) own the cloud-based solution called
                  Canyou” including all instructions in hard copy or electronic
                  form and any update, modification or release of any part of
                  the solution (Services).
                </ListItem>
                <ListItem listStyleType="none">
                  1.2 This End User License Agreement (EULA) applies to: (a) the
                  company, entity or organisation who is the primary account
                  holder of the Services (Account Holder); (b) any individual
                  who is invited by the Account Holder to act as an end user for
                  the Account Holder (User Account) in accordance with the terms
                  of your Account Holder’s agreement with us (Account Holder
                  Agreement); and (c) any other person or entity using or
                  accessing the Services, (jointly and severally known as you or
                  your).
                </ListItem>
                <ListItem listStyleType="none">
                  1.3 You accept this EULA by clicking the box indicating
                  acceptance when requested by us or by using or accessing the
                  Services.
                </ListItem>
                <ListItem listStyleType="none">
                  1.4 This EULA will commence on the date on which you start
                  using the Services and will continue until the date that you
                  permanently stop using the Services and/or your User Account
                  is terminated in accordance with clause 10 (the Term).
                </ListItem>
                <ListItem listStyleType="none">
                  1.5 By accepting this EULA, creating a User Account and/or
                  accessing and/or using the Services, you warrant to us that
                  you have the legal capacity to enter into a legally binding
                  agreement.
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Licence Subject to your compliance with the terms of this EULA, we
              grant you a non-exclusive, non-transferable, non-sublicensable,
              personal and revocable licence to access and use the Services for
              the Term as permitted under the Account Holder Agreement
              (Licence).
            </ListItem>
            <ListItem>
              Restrictions on use
              <UnorderedList>
                <ListItem listStyleType="none">
                  3.1 You must not access or use the Services except as
                  permitted by the Licence and you must not and must not permit
                  any other person to: (a) use the Services in any way that
                  breaches any applicable Laws or infringes any person&apops;s
                  rights, including Intellectual Property Rights (and privacy
                  rights); (b) use the Services in any way that damages,
                  interferes with or interrupts the supply of the Services; (c)
                  introduce malicious programs into our hardware and software or
                  systems, including viruses, worms, trojan horses and e-mail
                  bombs; (d) allow others to access or use your/their Account,
                  password or authentication details; (e) continue to use the
                  Services after termination of your employment and/or
                  relationship with the Account Holder; (f) use the Services to
                  carry out security breaches or disruptions of a network. This
                  includes accessing data where you are not the intended
                  recipient or logging into a server or account that you are not
                  expressly authorised to access or corrupting any data
                  (including network sniffing/monitoring, pinged floods, packet
                  spoofing, denial of service and forged routing information for
                  malicious purposes); (g) use any program/script/command, or
                  send messages of any kind, with the intent to interfere with,
                  or disable, any person’s use of the Services; (h) use the
                  Services to circumvent user authentication or security of any
                  of our networks, accounts or hosts or those of members or
                  suppliers; (i) use the Services to transmit, publish or
                  communicate material that is defamatory, offensive, abusive,
                  indecent, menacing or unwanted; and (j) send any form of
                  harassment via email, or any other form of messaging, whether
                  through language, frequency, or size of messages, or use the
                  Services in breach of any person&apops;s privacy (such as by
                  way of identity theft or &quot;phishing&quot;).
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Your warranties
              <UnorderedList>
                <ListItem listStyleType="none">
                  4.1 You warrant and agree that: (a) you have reviewed and
                  understand the terms of this EULA and our Privacy Policy; (b)
                  you have all hardware, software and capabilities which are
                  necessary to access and use the Services; (c) you will use the
                  Services in accordance with this EULA; (d) all information and
                  documentation that you provide to us in connection with this
                  EULA is true, correct and complete and you acknowledge and
                  agree that we will rely on such information and documentation
                  in order to provide the Services; (e) in entering into this
                  EULA, you have not relied upon any representation, warranty or
                  inducement by us nor is any representation, warranty or thing
                  made or done by us to be inferred, incorporated or implied
                  into this EULA; (f) the provision of the Services may be
                  contingent on, or impacted by, Third Party Inputs; (g) we may
                  use third party service providers to integrate with or host
                  the Services. If the providers of third-party applications or
                  services cease to make their services or programs available on
                  reasonable terms, we may cease providing any affected features
                  without Liability; (h) the Services may use third party
                  products, facilities or services. We do not make any warranty
                  or representation in respect of the third-party products,
                  facilities or services; (i) we are not responsible for the
                  integrity or existence of any data on the Computing
                  Environment, network or any device controlled by you; (j) you
                  are responsible for obtaining any consents, licences,
                  authorities and permissions from other parties necessary for
                  the Services to be provided in accordance with this EULA, at
                  your cost, and for providing us with the necessary consents,
                  licences, authorities and permissions; and (k) if applicable,
                  you will maintain the confidentiality and security of any of
                  your Account and/or User Account details and passwords.
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Intellectual Property Rights
              <UnorderedList>
                <ListItem listStyleType="none">
                  5.1 The Parties agree that nothing in this EULA constitutes a
                  transfer or assignment of any Intellectual Property Rights.
                  Our Intellectual Property Rights
                </ListItem>
                <ListItem listStyleType="none">
                  5.2 You agree that we own all Intellectual Property Rights in:
                  (a) Our Materials; (b) New Materials or Improvements; and (c)
                  any Feedback, and these Intellectual Property Rights will at
                  all times vest, or remain vested, in us (or, if applicable,
                  our third-party service providers). To the extent that
                  ownership of these Intellectual Property Rights does not
                  automatically vest in us, you agree to do all acts necessary
                  or desirable to assure our title to such rights.
                </ListItem>
                <ListItem listStyleType="none">
                  5.3 In the use of any Intellectual Property Rights in
                  connection with this EULA, you agree that you must not commit
                  any Intellectual Property Breach.
                </ListItem>
                <ListItem listStyleType="none">
                  5.4 You also agree that: (a) we may use Feedback in any manner
                  which we see fit (including to develop new features) and no
                  benefit will be due to you as a result of any use of any
                  Feedback; and (b) you must not whether directly or indirectly,
                  without our prior written consent: (1) copy, modify, adapt,
                  translate, create a derivative work of, reverse engineer,
                  reverse assemble, disassemble or decompile the Services or
                  otherwise attempt to discover any part of the source code of
                  the Services; (2) use any unauthorised, modified version of
                  the Services, including (without limitation) for the purpose
                  of building similar or competitive software or for the purpose
                  of obtaining unauthorised access to the Services; (3) use the
                  Services in a manner that is contrary to any Laws or in
                  violation of any Intellectual Property Rights or privacy
                  rights; (4) publish, post, upload or otherwise transmit data
                  that contains any viruses or malware or other computer
                  programming routines that are intended to damage,
                  detrimentally interfere with information or property of any
                  person; (5) use or knowingly permit the use of any security
                  testing tools in order to probe, scan or attempt to penetrate
                  or ascertain the security of the Services; (6) unless
                  authorised under this agreement, use the Services in a
                  web-enabled form for the purposes of third-party analysis or
                  view via the internet or other external network access method;
                  (7) rent the use of the Services to any third parties; (8)
                  take any action that may compromise or jeopardise our
                  Intellectual Property Rights in the Services or otherwise; (9)
                  remove or deface any confidentiality, copyright or other
                  proprietary notice placed on the Services; (10) make any
                  representations or warranties to any third parties that could
                  be construed as being representations or warranties from us in
                  relation to the Services or any other matter; or (11) use the
                  Services in any way that involves service bureau use,
                  outsourcing, renting, reselling, sublicensing, concurrent use
                  of a single user login, or time-sharing. Data
                </ListItem>
                <ListItem listStyleType="none">
                  5.5 You agree that under the Account Holder Agreement, the
                  Data belongs to the Account Holder and as such we disclose the
                  Data to the Account Holder in accordance with the Account
                  Holder Agreement.
                </ListItem>
                <ListItem listStyleType="none">
                  5.6 You grant us a limited licence to copy, transmit, store
                  and back-up or otherwise access the Data during the Term (and
                  for a reasonable period after the Term), to: (a) supply the
                  Services to you (including to enable you to access and use the
                  Services); (b) diagnose problems with the Services; (c)
                  enhance and otherwise modify the Services; (d) perform
                  Analytics; (e) develop other services, provided the Data is
                  de-identified; and (f) as reasonably required to perform our
                  obligations under this EULA.
                </ListItem>
                <ListItem listStyleType="none">
                  5.7 You acknowledge and agree that: (a) you must, at all
                  times, ensure the integrity of the Data and that the
                  collection and use of the Data is compliant with all Laws; (b)
                  we assume no responsibility or Liability for the Data. You are
                  solely responsible for the Data and the consequences of using,
                  disclosing, storing or transmitting it.; and (c) the operation
                  of the Services is reliant on the accuracy of the Data, and
                  the provision of inaccurate or incomplete Data by you may
                  affect the use, output and operation of the Services.
                </ListItem>
                <ListItem listStyleType="none">
                  5.8 You represent and warrant that: (a) you have obtained all
                  necessary rights, releases and permissions to provide all Data
                  to us and to grant the rights granted to us in this EULA; (b)
                  the Data is accurate and complete; (c) the Data (and its
                  transfer to and use by us) as authorised by you, under this
                  EULA does not violate any Laws (including those relating to
                  export control and electronic communications) or rights of any
                  third party, including any Intellectual Property Rights,
                  rights of privacy, or rights of publicity; and (d) any use,
                  collection and disclosure authorised in this EULA is not
                  inconsistent with the terms of any applicable privacy
                  policies.
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Analytics
              <UnorderedList>
                <ListItem listStyleType="none">
                  6.1 Despite anything to the contrary, we may monitor, analyse
                  and compile statistical and performance information based on
                  and/or related to your use of the Services, in an aggregated
                  and anonymised format (Analytics). You agree that we may make
                  such Analytics publicly available, provided that it: (a) does
                  not contain identifying information; and (b) is not compiled
                  using a sample size small enough to make the underlying Data
                  identifiable.
                </ListItem>
                <ListItem listStyleType="none">
                  6.2 We, and our licensors own all right, title and interest in
                  and to the Analytics and all related software, technology,
                  documentation and content used or provided in connection with
                  the Analytics, including all Intellectual Property Rights in
                  the foregoing.
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Privacy
              <UnorderedList>
                <ListItem listStyleType="none">
                  7.1 You are responsible for the collection, use, storage and
                  otherwise dealing with Personal Information related to you and
                  all matters relating to the Data.
                </ListItem>
                <ListItem listStyleType="none">
                  7.2 Without limiting this clause 7, you may only disclose
                  Personal Information in your control to us, if: (a) you are
                  authorised by privacy Laws to collect the Personal Information
                  and to use or disclose it in the manner required by this EULA;
                  (b) you have informed the individual to whom the Personal
                  Information relates, that it might be necessary to disclose
                  the Personal Information to third parties; and (c) where any
                  Personal Information is Sensitive Information, you have
                  obtained the specific consent to that disclosure from the
                  individual to whom the Sensitive Information relates.
                </ListItem>
                <ListItem listStyleType="none">
                  7.3 We agree to handle any Personal Information you provide to
                  us, solely for the purpose of providing the Services and as
                  otherwise set out in this EULA and in accordance with any
                  applicable Laws (and our Privacy Policy).
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Australian Consumer Law
              <UnorderedList>
                <ListItem listStyleType="none">
                  8.1 Certain legislation, including the Australian Consumer Law
                  (ACL) in the Competition and Consumer Act 2010 (Cth), and
                  similar consumer protection laws and regulations, may confer
                  you with rights, warranties, guarantees and remedies relating
                  to the Services which cannot be excluded, restricted or
                  modified (Statutory Rights). Nothing in this EULA excludes
                  your Statutory Rights as a consumer under the ACL.
                </ListItem>
                <ListItem listStyleType="none">
                  8.2 You agree that our Liability for the Services is governed
                  solely by the ACL and this EULA.
                </ListItem>
                <ListItem listStyleType="none">
                  8.3 Subject to your Statutory Rights, we exclude all express
                  and implied warranties, representations and guarantees of any
                  kind (whether under statute, law, equity or on any other
                  basis) and all materials, work, goods and services (including
                  the Services) are provided to you without warranties,
                  representations and guarantees of any kind, unless expressly
                  stipulated in this EULA.
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Our Liability
              <UnorderedList>
                <ListItem listStyleType="none">
                  9.1 Despite anything to the contrary, to the maximum extent
                  permitted by law: (a) we will not be liable for any
                  Consequential Loss; (b) a Party’s liability for any Liability
                  under this EULA will be reduced proportionately to the extent
                  the relevant Liability was caused or contributed to by the
                  acts or omissions of the other Party (or any of its
                  Personnel), including any failure to mitigate that Liability;
                  and (c) our aggregate liability for any Liability arising from
                  or in connection with this EULA will be limited to, and must
                  not exceed in aggregate for all claims, $1.
                </ListItem>
                <ListItem listStyleType="none">
                  9.2 Despite anything to the contrary, to the maximum extent
                  permitted by law, we will not be liable for, and you waive and
                  release us from and against, any Liability, caused or
                  contributed to by, arising from or connected with: (a) loss
                  of, or damage to, any property or any injury to or loss to any
                  person; (b) loss of use and/or loss or corruption of data
                  including Data; (c) the Computing Environment; (d) any breach
                  by you of this EULA or any Laws; (e) any reliance on the
                  Services by you, including for the purposes of complying with
                  any obligations on you (including under any Laws); (f) your
                  acts or omissions; (g) any use or application of the Services
                  by a person or entity other than you, or other than as
                  reasonably contemplated by this EULA; (h) any Third-Party
                  Inputs; and (i) any event outside of our reasonable control
                  (including a Force Majeure Event, and a fault, defect, error
                  or omission in the Computing Environment or Data).
                </ListItem>
                <ListItem listStyleType="none">
                  9.3 To the maximum extent permitted by law, you indemnify and
                  continue to indemnify us against all Liability we suffer or
                  incur arising from or as a consequence of a breach of clause 5
                  (Intellectual Property), your use of the Services contrary to
                  this EULA, including from any claim relating to the Data.
                </ListItem>
                <ListItem listStyleType="none">
                  9.4 You acknowledge and agree that: (a) you are responsible
                  for your use of the Services; (b) you use the Services and any
                  associated programs and files at your own risk; (c) we do not
                  warrant that the Services are error-free or will be
                  uninterrupted; and (d) we may pursue any available equitable
                  or other remedy against you if you breach any provision of
                  this EULA.
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Termination of your User Account
              <UnorderedList>
                <ListItem listStyleType="none">
                  10.1 Your Account Holder may terminate your User Account
                  and/or your access at any time in their sole discretion.
                </ListItem>
                <ListItem listStyleType="none">
                  10.2 If the Account Holder Agreement is suspended or
                  terminated, we may also suspend your User Account, your access
                  or terminate your User Account.
                </ListItem>
                <ListItem listStyleType="none">
                  10.3 We may, with notice to you, immediately suspend and/or
                  terminate your User Account at any time, including if we
                  believe you are misusing the Services or otherwise in breach
                  of this EULA.
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Disputes
              <UnorderedList>
                <ListItem listStyleType="none">
                  11.1 Neither Party may commence court proceedings relating to
                  any dispute, controversy or claim arising from, or in
                  connection with, this EULA (including any question regarding
                  its existence, validity or termination) (Dispute) without
                  first meeting with the other Party to seek (in good faith) to
                  resolve the Dispute.{' '}
                </ListItem>
                <ListItem listStyleType="none">
                  11.2 If the parties cannot agree how to resolve the Dispute at
                  that initial meeting, either Party may refer the matter to a
                  mediator. If the parties cannot agree on who the mediator
                  should be, either Party may ask the Law Society of New South
                  Wales to appoint a mediator. The mediator will decide the
                  time, place and rules for mediation. The parties agree to
                  attend the mediation in good faith, to seek to resolve the
                  Dispute. The costs of the mediation will be shared equally
                  between the parties.
                </ListItem>
                <ListItem listStyleType="none">
                  11.3 If the parties are unable to reach a resolution through
                  mediation, either Party may refer the matter to arbitration
                  administered by the Australian Centre for International
                  Commercial Arbitration (ACICA), with such arbitration to be
                  conducted in Sydney, New South Wales, in English and in
                  accordance with the ACICA Rules. The costs of the arbitration
                  will be shared equally between the Parties and the
                  determination of the arbitrator will be final and binding.
                </ListItem>
                <ListItem listStyleType="none">
                  11.4 Nothing in this clause will operate to prevent a Party
                  from seeking urgent injunctive or equitable relief from a
                  court of appropriate jurisdiction.
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              General
              <UnorderedList>
                <ListItem listStyleType="none">
                  12.1 We reserve the right at any time and from time to time to
                  change or remove features of the Services.
                </ListItem>
                <ListItem listStyleType="none">
                  12.2 Any notice given under this EULA must be in writing
                  addressed to the relevant address last notified by the
                  recipient to the Parties. Any notice may be sent by standard
                  post or email, and will be deemed to have been served on the
                  expiry of 2 Business Days in the case of post, or at the time
                  of transmission in the case of transmission by email (or,
                  where the time of transmission is not on a Business Day, 9am
                  on the next Business Day).
                </ListItem>
                <ListItem listStyleType="none">
                  12.3 Any failure or delay by a Party in exercising a power or
                  right (either wholly or partly) in relation to this EULA does
                  not operate as a waiver or prevent a Party from exercising
                  that power or right or any other power or right. A waiver must
                  be in writing.
                </ListItem>
                <ListItem listStyleType="none">
                  12.4 If a provision of this EULA is held to be void, invalid,
                  illegal or unenforceable, that provision is to be read down as
                  narrowly as necessary to allow it to be valid or enforceable,
                  failing which, that provision (or that part of that provision)
                  will be severed from this EULA without affecting the validity
                  or enforceability of the remainder of that provision or the
                  other provisions.
                </ListItem>
                <ListItem listStyleType="none">
                  12.5 We may assign, transfer, novate or otherwise deal with
                  all or any of our rights or obligations under this EULA
                  without your prior written consent. You may not assign,
                  transfer or otherwise deal with all or any of your rights or
                  obligations under this EULA without our prior written consent.
                  Any purported dealing in breach of this clause by you is of no
                  force or effect.
                </ListItem>
                <ListItem listStyleType="none">
                  12.6 We may vary this EULA with notice to you via email or via
                  a notification within your Account Holder and/or User Account
                  notices.
                </ListItem>
                <ListItem listStyleType="none">
                  12.7 This EULA is governed by the laws of New South Wales,
                  Australia. You irrevocably and unconditionally submit to the
                  exclusive jurisdiction of the courts operating in New South
                  Wales and any courts entitled to hear appeals from those
                  courts and waive any rights to object to proceedings being
                  brought in those courts.
                </ListItem>
                <ListItem listStyleType="none">
                  12.8 Clauses 2, 4, 5, 6, 7, 8, 9, 10 and 11 will survive
                  termination or expiry of this EULA.
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Definitions The following words have the meanings as set out
              below. Business Day means a day which is not a Saturday, Sunday or
              bank or public holiday in New South Wales, Australia. Computing
              Environment means your computing environment including all
              hardware, software, information technology and telecommunications
              services and Systems. Consequential Loss includes any indirect,
              incidental or consequential loss, loss of profits, revenue,
              production, opportunity, access to markets, goodwill, reputation,
              use or any remote, abnormal or unforeseeable loss, loss of use
              and/or loss or corruption of data or any loss or damage relating
              to business interruption, or otherwise, suffered or incurred by a
              person, arising out of or in connection with this EULA (whether
              involving a third party or a Party to this EULA or otherwise).
              Data means the information, materials, documents, licences,
              certifications, logos, documents, qualifications and other
              Intellectual Property or data inputted by you into the Services or
              stored by the Services or generated by the Services as a result of
              your use of the Services. Feedback means any idea, suggestion,
              recommendation or request by you, whether made verbally, in
              writing, directly or indirectly, in connection with the Services.
              Force Majeure Event means an event which is beyond our reasonable
              control including but not limited to epidemics, pandemics, and
              Government sanctioned restrictions and orders, whether known or
              unknown at the time of entering into these Terms. Improvements
              means any development, modification, adaptation or improvement of
              Our Materials or any New Materials made by or on behalf of either
              Party (or any of their respective Personnel), or in respect of
              which Intellectual Property Rights are acquired by, either Party
              during the Term. Intellectual Property includes any and all
              intellectual and industrial property rights throughout the world,
              whether subsisting now or in the future and includes all copyright
              and analogous rights, all rights in relation to inventions
              (including patent rights), registered and unregistered trademarks,
              designs (whether or not registered or registrable), circuit
              layouts, trade names, trade secrets, business names, customer
              names or internet domain names. Intellectual Property includes the
              Services. Intellectual Property Breach means any breach by you of
              any of our Intellectual Property Rights (or any breaches of
              third-party rights including any Intellectual Property Rights of
              third parties), including, but not limited, to you: (a) copying,
              altering, enhancing, adapting or modifying any of our Intellectual
              Property; (b) creating derivative works from our Intellectual
              Property; (c) providing or disclosing our Intellectual Property
              to, or allowing our Intellectual Property to be used by, any third
              party; (d) assigning or transferring any of our Intellectual
              Property Rights or granting sublicenses of any of our Intellectual
              Property Rights, except as expressly permitted in this EULA; (e)
              reverse engineering or decompiling any of our Intellectual
              Property Rights, except where permitted by Law; or (f) using or
              exploiting our Intellectual Property for purposes other than as
              expressly stated in this EULA (including, without limitation,
              using our Intellectual Property for commercial purposes or
              on-selling our Intellectual Property to third parties).
              Intellectual Property Rights means for the duration of the rights
              in any part of the world, any industrial or intellectual property
              rights, whether registrable or not, including in respect of
              Intellectual Property. Laws means acts, ordinances, regulations,
              rules, code and by-laws of the Commonwealth or any state or
              territory and includes the Privacy Act 1988 (Cth) and the Spam Act
              2003 (Cth). Liability means any loss, liability, cost, payment,
              damages, debt or expense (including reasonable legal fees). New
              Materials means all Intellectual Property developed, adapted,
              modified or created by or on behalf of us or you or our respective
              Personnel in connection with this EULA and Services (including any
              machine learning algorithms output from the Services and any data
              or information generated by your use of the Services), whether
              before or after the date of this EULA. Our Materials means all
              work, models, processes, technologies, strategies, materials,
              information, documentation and services (including our
              Intellectual Property and Services) that we may provide to you
              under this EULA, and which may contain material which is owned by
              or licensed to us, and is protected by Australian and
              international laws. Party means a party to this EULA. Personal
              Information is defined in the Privacy Act 1988 (Cth) and also
              includes any similar term as defined in any other privacy law
              applicable to you; Privacy Act means the Privacy Act 1988 (Cth).
              Privacy Policy means any privacy policy set out on our website.
              Sensitive Information is defined in the Privacy Act. Statutory
              Rights has the meaning given in clause 8 of this EULA. System
              means all hardware, software, networks and other IT systems used
              by a Party from time to time, including a network. Third Party
              Inputs means third parties or any goods and services provided by
              third parties, including customers, end users, suppliers,
              transportation or logistics providers or other subcontractors
              which the provision of the Services may be contingent on, or
              impacted by. API Software Pty Ltd t/a Canyou Software ABN (57 650
              764 195) Address: 3/55 Pyrmont Bridge Road Pyrmont NSW 2009 Email:
              support@canyou.com.au Last update: 8 June 2021 END USER LICENSE
              AGREEMENT
            </ListItem>
          </OrderedList>
        </VStack>
      </Flex>
    </Layout>
  );
};

export default EndUserLicenseAgreement;
